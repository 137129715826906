import Vue from "vue";
import Router from "vue-router";
import { api } from "@/state/services";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      component: () => import("./views/home"),
      meta: { authRequired: true },
    },
    {
      path: "/login",
      component: () => import("./views/login"),
      meta: { authRequired: false },
    },
    {
      path: "/login/token/:token",
      component: () => import("./views/login-in"),
      meta: { authRequired: false },
    },
    {
      path: "/logout",
      redirect: "/login",
    },
    {
      path: "/register",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/register/:sponsor",
      component: () => import("./views/register"),
      meta: { authRequired: false },
    },
    {
      path: "/password-forgot",
      component: () => import("./views/password-forgot"),
      meta: { authRequired: false },
    },
    {
      path: "/password-change",
      component: () => import("./views/password-change"),
      meta: { authRequired: false },
    },
    {
      path: "/account",
      component: () => import("./views/account/account"),
      meta: { authRequired: true },
    },
    {
      path: "/account/documents",
      component: () => import("./views/account/documents"),
      meta: { authRequired: true },
    },
    {
      path: "/account/address",
      component: () => import("./views/account/address"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password",
      component: () => import("./views/account/password"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/account/password/security/:token",
      component: () => import("./views/account/password-security"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/extract",
      component: () => import("./views/wallet/extract"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/transfer",
      component: () => import("./views/wallet/transfer"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/pay",
      component: () => import("./views/wallet/pay"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/pay/:id",
      component: () => import("./views/wallet/pay"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal",
      component: () => import("./views/wallet/withdrawal"),
      meta: { authRequired: true },
    },
    {
      path: "/wallet/withdrawal/history",
      component: () => import("./views/wallet/withdrawal-history"),
      meta: { authRequired: true },
    },
    {
      path: "/downloads",
      component: () => import("./views/downloads"),
      meta: { authRequired: true },
    },
    {
      path: "/faq",
      component: () => import("./views/faq"),
      meta: { authRequired: true },
    },
    {
      path: "/tickets",
      component: () => import("./views/tickets"),
      meta: { authRequired: true },
    },
    {
      path: "/tickets/:id",
      component: () => import("./views/tickets-view"),
      meta: { authRequired: true },
    },
    {
      path: "/404",
      component: () => import("./views/404"),
      meta: { authRequired: false },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  document.title = process.env.VUE_APP_TITLE;
  next();

  if (to.meta.authRequired && !loggedIn) {
    return next("/login");
  }

  if (to.meta.authRequired && loggedIn) {
    api
      .get("user")
      .then((response) => {
        if (response.data && response.data.status == "success") {
          router.app.$store.state.account.user = response.data.user;

          return next();
        } else {
          return next("/login");
        }
      })
      .catch(error => {
        if (error) {
          return next("/login");
        }
      });
  } else {
    next();
  }
});

export default router;
